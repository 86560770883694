import React from "react";
import logo from "../assets/ColoredLogoTricornerX.png";
const THEMES = [
  "light",
  "dark",
  "cupcake",
  "bumblebee",
  "emerald",
  "corporate",
  "synthwave",
  "retro",
  "cyberpunk",
  "valentine",
  "halloween",
  "garden",
  "forest",
  "aqua",
  "lofi",
  "pastel",
  "fantasy",
  "wireframe",
  "black",
  "luxury",
  "dracula",
  "cmyk",
  "autumn",
  "business",
  "acid",
  "lemonade",
  "night",
  "coffee",
  "winter",
];

export default function Header() {
  const [theme, setTheme] = React.useState("coffee");

  React.useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    setTheme(theme);
  }, [theme]);

  // const handleThemeChange = (e) => {
  //   const val = e.target.getAttribute("data-set-theme");
  //   setTheme(val);
  // };

  return (
    <header className="bg-base-100 py-2 sticky top-0 z-50">
      <div className="container">
        <div className="navbar px-0">
          <div className="navbar-start">
            <div className="dropdown">
              <label
                tabIndex={0}
                className="btn btn-circle btn-primary lg:hidden mr-1"
              >
                <i className="bi bi-list text-2xl"></i>
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content mt-1 w-52 menu menu-compact p-2 bg-base-200 shadow rounded-box"
              >
                <li>
                  <a href="#!">Home</a>
                </li>
                <li>
                  <a href="#!">About</a>
                </li>
                <li>
                  <div
                    className="dropdown dropdown-end"
                    onClick={() =>
                      window.open("https://app.tricornerx.com", "_blank")
                    }
                  >
                    Sign In
                  </div>
                </li>
              </ul>
            </div>
            <div className="normal-case text-2xl">
              <img
                src={logo}
                className="w-full h-50"
                alt="Tricorner X Delivery Services"
              />
              {/**/}
            </div>
          </div>
          <div className="navbar-end hidden lg:flex">
            <ul className="menu menu-horizontal p-0 font-medium">
              <li>
                <a className="dropdown dropdown-end" href="#!">
                  Home
                </a>
              </li>
              <li>
                <a className="dropdown dropdown-end" href="#!">
                  About
                </a>
              </li>
              <li>
                <div
                  className="dropdown dropdown-end"
                  onClick={() =>
                    window.open("https://app.tricornerx.com", "_blank")
                  }
                >
                  Sign In
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
